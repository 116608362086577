<template>
	<div id="datatable">
		<div v-for="(dataSub, index) in data" :key="index"
			 class="data-set">

			<div class="header">
				<h1>{{dataSub.wahlkreis}}</h1>
				<input class="form-control"
					   @keyup="onQuickFilterChange" type="text" :id=index
					   placeholder="Type text to filter..."/>
			</div>
			<ag-grid-vue style="width: 100%; height: 650px;"
						 class="datatable ag-theme-alpine"
						 :rowData="dataSub.data"
						 :columnDefs="columns"
						 :modules="modules" @grid-ready="onReady"
						 :defaultColDef="{
							sortable: true,
							resizable: true,
						 }"

						 :gridOptions="dataSub.gridOptions"
						 :groupHeaders="true"
						 :suppressRowClickSelection="true"
						 rowSelection="multiple"
			>
			</ag-grid-vue>
		</div>
	</div>
</template>

<script>
	import {AgGridVue} from "@ag-grid-community/vue";
	import Full2020 from '../data/2023_bgr.json';
	import {AllCommunityModules} from "@ag-grid-community/all-modules";

	export default {
		name: "DataTable",
		components: {
			AgGridVue
		},
		props: ['party'],
		methods: {
			onReady(params) {
				//params.api.sizeColumnsToFit();
			},
			onQuickFilterChange(event) {
				console.log(event.target.value);
				console.log(this.data[event.target.id]);
				this.data[event.target.id].gridOptions.api.setQuickFilter(event.target.value);
			}
		},
		mounted() {
			this.columns = Object.freeze([
				{
					headerName: 'Name',
					field: 'ganzer_name'
				},
				/*{
					headerName: 'Geschlecht',
					field: 'geschlecht',
					width: '100'
				},*/
				{
					headerName: 'Rangfolge',
					field: 'rangfolge',
					width: '100',
					sort: 'asc'
				},
				{
					headerName: 'Total',
					field: 'stimmen_total_aus_wahlzettel',
					width: '80'
				},
				{
					headerName: 'Veränderte',
					field: 'stimmen_veranderte_wahlzettel',
					width: '80'
				},
				{
					headerName: 'Ohne',
					field: '00_ohne',
					width: '80'
				},
				{
					headerName: 'SP',
					field: '05_sp',
					width: '80'
				},
				{
					headerName: 'Grüne',
					field: '17_gp',
					width: '80'
				},
				{
					headerName: 'BastA!',
					field: '27_basta',
					width: '80'
				},
				{
					headerName: 'LDP',
					field: '03_ldp',
					width: '80'
				},
				{
					headerName: 'SVP',
					field: '12_svp',
					width: '80'
				},
				{
					headerName: 'FDP',
					field: '01_fdp',
					width: '80'
				},
				{
					headerName: 'GLP',
					field: '10_glp',
					width: '80'
				},
				{
					headerName: 'Mitte',
					field: '07_mitte',
					width: '80'
				},
				{
					headerName: 'EVP',
					field: '04_evp',
					width: '80'
				},
				{
					headerName: 'Unabhängige',
					field: '30_unabh',
					width: '80'
				}
			]);

			console.log('>>> raw data');

			console.log(this.rawData);

			let spdata = this.rawData.filter(set => {
				return set.parteikurzbezeichnung == this.party;
			});

			this.data.push({
				wahlkreis: 'Alle',
				data: spdata,
				gridOptions: {}
			});
		},
		data() {
			return {
				columns: [],
				modules: AllCommunityModules,
				rawData: Full2020,
				data: [],
			}
		}
	}
</script>

<style>
	#datatable {
		width: 85%;
		display: flex;
		align-items: center;
		justify-content: space-around;
		flex-direction: column;
		margin-top: 15px;
	}

	.data-set {
		width: 100%;
		display: flex;
		justify-content: space-around;
		flex-direction: column;
		align-items: center;
		margin-bottom: 50px;
	}

	.datatable {
		width: 100%;
		height: 350px;
	}

	.ag-theme-alpine .ag-row,
	.datatable {
		font-size: 12px;
	}

	.header {
		width: 100%;
		background-color: #00a18d;
		display: flex;
		justify-content: space-between;
		color: white;
		align-items: center;
		padding: 10px;
	}

	.form-control {
		width: 35% !important;
	}
</style>