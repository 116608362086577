<template>
	<div id="table">
		<div class="select">
			<v-select :options="partyList" :reduce="party => party.code"
								label="name" v-model="defaultSelected" :value="party" dense
								solo :clearable="false" @input="changeParty"
			/>
		</div>
		<DataTableBGR v-if="renderSankey" :party="party"/>
	</div>
</template>

<script>
import DataTableBGR from '@/components/DataTableBGR.vue';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
	name: "Table",
	components: {
		DataTableBGR,
		vSelect
	},
	methods: {
		changeParty(party) {
			this.party = party;

			this.renderSankey = false;

			this.$nextTick(() => {
				this.renderSankey = true;
			});
		}
	},
	data() {
		return {
			party: 'SP',
			renderSankey: true,
			defaultSelected: {
				name: 'Sozialdemokratische Partei',
				code: "SP"
			},
			partyList: [
				{
					name: 'Sozialdemokratische Partei',
					code: "SP"
				},
				{
					name: 'Grüne',
					code: "GP"
				},
				{
					name: 'BastA!',
					code: "BastA"
				},
				{
					name: 'LDP Liberal-Demokratische',
					code: "LDP"
				},
				{
					name: 'Schweizerische Volkspartei',
					code: "SVP"
				},
				{
					name: 'Grünliberale',
					code: "GLP"
				},
				{
					name: 'FDP.Die Liberalen',
					code: "FDP"
				},
				{
					name: 'Mitte',
					code: "MITTE"
				},
				{
					name: 'Evangelische Volkspartei',
					code: "EVP"
				},
			]
		}
	}
}
</script>

<style scoped>
#table {
	margin-top: 43px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-direction: column;
}

.select {
	margin-top: 50px;
	width: 100%;
	margin-bottom: 15px;
	margin-left: 15%;
}
.v-select {
	background: white !important;
	width: 35%;
}
</style>